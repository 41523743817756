import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


  constructor(private _router: Router,private _authenticationService: AuthService) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.authToken;
    
    const _BackendUrl = request.url.startsWith(environment._BackendUrl);

    const isForgotPasswordRoute = request.url.endsWith('/forgot-password');
    const isResetPasswordRoute = /\/reset_password(\/.+)?$/.test(request.url);
    const isCreateCoursierdRoute = /\/inscription-cycliste\/.+$/.test(request.url);

    if (isForgotPasswordRoute || isResetPasswordRoute || isCreateCoursierdRoute ) {
      return next.handle(request);
    }
    
    if (isLoggedIn && _BackendUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authToken}`
        }
      });
    }else{
      if(!isLoggedIn) this._router.navigate(['/auth/login']);
    }

    return next.handle(request);
  }
}
