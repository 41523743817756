import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private _router: Router, private _authenticationService: AuthService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    const isResetPasswordRoute = /\/reset-password\/.+$/.test(state.url);

    const isCreateCoursierdRoute = /\/inscription-cycliste\/.+$/.test(state.url);
    
    if (isResetPasswordRoute) {
      return true;
    }

    if (isCreateCoursierdRoute) {
      return true;
    }

    if (currentUser) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
