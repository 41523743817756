import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

const API_USERS_URL = `${environment._BackendUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {

  private userDataSource = new BehaviorSubject<any>(null);
  currentUserData = this.userDataSource.asObservable();

  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}login`, {
      email:email,
      password:password,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}me`, {
      headers: httpHeaders,
    });
  }

  reset_password(token: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}reset_password`, {
      token:token,
      newPassword:password,
    });
  }

  inscription_coursier(result: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}inscription_coursier`, result);
  }

  updateUserData(data: any) {
    this.userDataSource.next(data);
  }

}
